import {useEffect, useState} from 'react'
import {closeSpinner, openSpinner} from "@paytheory/pay-theory-ui"
import * as ROUTES from "../constants/routes";
import * as general from '../constants/general';

const generateMenu = () => {
    return [{
        to: ROUTES.MANAGE_MERCHANTS,
        className: "active",
        tag: "manage-merchants",
        icon: "building",
        label: "Manage Merchants",
        isCategory: false
    },
        {
            to: ROUTES.MANAGE_ADMINS,
            className: "inactive",
            tag: "manage-administrators",
            icon: "users-cog",
            label: "Manage Administrators",
            isCategory: false
        },
        {
            to: ROUTES.PAYMENT_PARAMETERS,
            className: "inactive",
            tag: "payment-parameters",
            icon: "file-invoice-dollar",
            label: "Payment Parameters",
            isCategory: false
        },
        {
            to: ROUTES.SETTINGS,
            className: "inactive",
            tag: "settings",
            icon: "cog",
            label: "Settings",
            isCategory: false
        }
    ];
};

const prepRoute = (route, replacements = []) => {
    return replacements.reduce((prepped, item) => {
        const keyed = `:${item.key}`
        return prepped.replace(keyed, item.value)
    }, route)
}

export {
    generateMenu,
    prepRoute
};

export const useSpinner = () => {
    const [calls, setCalls] = useState(0)

    useEffect(() => {
        if (calls > 0) {
            openSpinner()
        } else {
            closeSpinner()
        }
    }, [calls])

    const callStarted = () => setCalls(calls + 1)

    const callEnded = () => setCalls(calls - 1)

    return {callStarted, callEnded}
}

export const formatBasisPoints = (bp) => {
    const originalAmount = 100000;
    const totalAmount = Math.round(originalAmount / (1 - bp / 10000));
    const fee = totalAmount - originalAmount;
    return ((fee / originalAmount) * 100).toFixed(2);
};


export const statusChip = {
    settled: {
        color: "grey",
        textColor: "white",
        text: "Settled"
    },
    reversed: {
        color: "yellow",
        textColor: "black",
        text: "Refunded"
    },
    refunded: {
        color: "yellow",
        textColor: "black",
        text: "Refunded"
    },
    pending: {
        color: "grey-2",
        textColor: "black",
        text: "Pending"
    },
    succeeded: {
        color: "mint",
        textColor: "black",
        text: "Succeeded"
    },
    failed: {
        color: "raspberry",
        textColor: "white",
        text: "Failed"
    },
    partially_refunded: {
        color: "yellow",
        textColor: "black",
        text: "Partially Refunded"
    }
};

export const paymentParametersStatusChip = [
    {
        color: "grey-2",
        textColor: "black",
        text: "Disabled"
    }, {
        color: "mint",
        textColor: "black",
        text: "Enabled"
    }
]

export const formatFee = (fee) => {
    return fee < 0
        ? `-${(Math.abs(fee) / 100).toFixed(2)}`
        : `${(fee / 100).toFixed(2)}`;
};

export const brandClasses = {
    VISA: "pay-theory-card-visa",
    DISCOVER: "pay-theory-card-discover",
    MASTERCARD: "pay-theory-card-mastercard",
    AMERICAN_EXPRESS: "pay-theory-card-american-express",
    CASH: "pay-theory-cash-badge",
    ACH: "pay-theory-ach-badge"
};

const addZero = value => {
    return value < 10 ? `0${value}` : value;
}
export const formatDate = (stamp) => {
    const dated = new Date(stamp);
    const month = dated.getMonth() + 1;
    const day = dated.getDate();
    const year = dated.getFullYear();
    return `${addZero(month)}/${addZero(day)}/${year}`;
};

export const formatFullDate = (stamp) => {
    const dated = new Date(stamp);
    const month = (dated.getMonth() + 1).toString().padStart(2, '0');
    const day = (dated.getDate()).toString().padStart(2, "0");
    const year = dated.getFullYear();
    const hour = (dated.getHours() % 12 || 12).toString().padStart(2, "0");
    const minute = (dated.getMinutes()).toString().padStart(2, "0");
    const amOrPm = dated.getHours() > 11 ? "PM" : "AM";
    return `${month}/${day}/${year} ${hour}:${minute} ${amOrPm}`;
};

const mapValue = (value, next) => {
    if (value.toLowerCase().includes("date")) {
        return formatFullDate(next[`${value}`]);
    } else if (value.toLowerCase().includes("amount") || value.toLowerCase().includes("fee") || value.toLowerCase().includes("adjustment")) {
        return `"${convertAmount(next[`${value}`], next.currency)}"`;
    } else {
        if(typeof value ==="string" && value.includes(',')) value = `"${value}"`;
        return next[`${value}`];
    }
};

export const convertAmount = (amount, currency = "USD") => {
    const nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2
    });
    return nf.format(amount / 100)
}

const camelToSnake = (str) => {
    return str.replace(/([A-Z])/g, (g) => `_${g[0].toLowerCase()}`);
};

function arrayToCSV(objArray) {
    if (objArray.length > 0) {
        const array =
            typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
        const str =
            `${Object.keys(array[0])
                .map((value) => `"${camelToSnake(value)}"`)
                .join(",")}` + "\r\n";

        return array.reduce((str, next) => {
            str +=
                `${Object.keys(next)
                    .map((value) => mapValue(value, next))
                    .join(",")}` + "\r\n";
            return str;
        }, str);
    }
}

export const downloadCSV = (items, fileName) => {
    const link = document.createElement("a");

    // Avoid scrolling to bottom
    link.style.top = "0";
    link.style.left = "0";
    link.style.position = "fixed";

    document.body.appendChild(link);
    const text = arrayToCSV(items)
    const data = new Blob([text], {type: "text/csv"});
    link.href = URL.createObjectURL(data);
    link.download = `${fileName}.csv`;
    link.onclick = (e) => {
        if (items.length === 0) e.preventDefault();
    };
    link.click();

    document.body.removeChild(link);
};

export const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const removeCountryCode = cleaned.substring(cleaned.length - 10);
    const match = removeCountryCode.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
}

export const findPaymentMethodLogo = (item) => {
    return item.cardBrand ? item.cardBrand : item.paymentType
}

export const compareState = (a, b) => {
    if (typeof a === 'object' && typeof b === 'object') {
        let result = false;
        Object.keys(a).forEach(key => {
            if (a[key] !== b[key]) {
                result = true;
            }
        });
        return result;
    } else {
        return a !== b;
    }
}

export const useDebounce = (value, action, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Set debouncedValue to value (passed in) after the specified delay
            const handler = setTimeout(() => {
                if(compareState(value, debouncedValue)){
                    setDebouncedValue(value);
                    action(value)
                }
            }, delay);

            // Return a cleanup function that will be called every time ...
            // ... useEffect is re-called. useEffect will only be re-called ...
            // ... if value changes (see the inputs array below).
            // This is how we prevent debouncedValue from changing if value is ...
            // ... changed within the delay period. Timeout gets cleared and restarted.
            // To put it in context, if the user is typing within our app's ...
            // ... search box, we don't want the debouncedValue to update until ...
            // ... they've stopped typing for more than 500ms.
            return () => {
                clearTimeout(handler);
            };
        },
        // Only re-call effect if value changes
        // You could also add the "delay" var to inputs array if you ...
        // ... need to be able to change that dynamically.
        [value]
    );

    return debouncedValue;
}

export const validDate = date => {
    return !!date.match(/^(0[1-9]|1[0-2]|[1-9])\/(0[1-9]|[12][0-9]|3[01]|[1-9])\/[0-9]{4}$/);
}

const addMonths = (date, months) => {
    const d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() !== d) {
        date.setDate(0);
    }
    return date;
}

const addDays = (date, days) => {
    date.setDate(date.getDate() + +days);
    return date;
}

const addYears = (date, years) => {
    var d = date.getDate();
    date.setFullYear(date.getFullYear() + +years);
    if (date.getDate() !== d) {
        date.setDate(0);
    }
    return date;
}

let dateOffsetValues = {
    '1D': {
        function: addDays,
        value: 1
    },
    '1W': {
        function: addDays,
        value: 7
    },
    '1M': {
        function: addMonths,
        value: 1
    },
    '3M': {
        function: addMonths,
        value: 3
    },
    '1Y': {
        function: addYears,
        value: 1
    }
}

export const findDateOffset = (value) => {
    let valueObject = dateOffsetValues[value];
    let date = valueObject.function(new Date(), -valueObject.value);
    let dateString = date.toISOString().split('T')[0];
    return dateString + 'T00:00:00.000Z';
}

export const formatDateToISO = (value, endOfDay) => {
    let date = new Date(value).toISOString()
    let dateString = date.split('T')[0]
    let ending = endOfDay ? 'T23:59:59.999Z' : 'T00:00:00.000Z';
    return dateString + ending;
}

export const capitalize = (string) => {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const parseSocketError = error => {
    // Return an empty string if the payload is empty.
    if (error.length === 0) return ""
    // Else return the first message in the payload
    return error[0].message
}

export const normalizeTransaction = transaction => {
    return {
        merchantUid: transaction.merchant_uid,
        transactionId: transaction.transaction_id,
        transactionDate: transaction.transaction_date,
        status: transaction.status,
        settlementBatch: transaction.settlement_batch,
        paymentType: transaction.payment_method?.payment_type?.toUpperCase(),
        cardBrand: transaction.payment_method?.card_brand?.toUpperCase(),
        lastFour: transaction.payment_method?.last_four,
        fullName: transaction.payment_method?.payor?.full_name,
        reference: transaction.reference,
        phone: transaction.payment_method?.payor?.phone,
        email: transaction.payment_method?.payor?.email,
        accountCode: transaction.account_code,
        transactionType: transaction.transaction_type,
        disputeStatus: transaction.dispute_status,
        netAmount: transaction.net_amount,
        grossAmount: transaction.gross_amount,
        refundedAmount: transaction.refunded_amount,
        fees: transaction.fees,
        currency: transaction.currency,
        failureReasons: transaction.failure_reasons,
        refundReason: transaction.refund_reason,
    }
}

export const onPagination = (func, limit, filter, totalPages, totalResults, resultsArray, page, setPage) => (action) => {
    let newPage = 0
    // Calculate new page
    switch (action) {
        case 'FIRST':
            newPage = 1
            break;
        case 'BACK':
            newPage = page - 1
            break;
        case 'FORWARD':
            newPage = page + 1
            break;
        case 'LAST':
            newPage = totalPages
            break;
        default:
            newPage = page
    }
    setPage(newPage)
    //Perform the action based on the new page
    if(newPage === 1) {
        func(null, general.DESC, null, limit, filter, general.FORWARD, false)
    } else if (newPage === totalPages) {
        func(null, general.ASC, null, totalResults % limit, filter, general.FORWARD, true)
    } else if (action === 'FORWARD') {
        const offset = resultsArray.slice(-1)[0]
        func(null, general.DESC, offset, limit, filter, general.FORWARD, false)
    } else if (action === 'BACK') {
        const offset = resultsArray[0]
        func(null, general.DESC, offset, limit, filter, general.BACKWARDS, false)
    }
}

export const resultsPerPageOptions = [
    {
        value: 10,
        label: '10'
    },
    {
        value: 25,
        label: '25'
    },
    {
        value: 50,
        label: '50'
    },
    {
        value: 100,
        label: '100'
    }
]